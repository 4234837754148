.post-item {
  padding-bottom: 3rem;

  &:not(:last-child) {
    margin-bottom: 3rem;
    border-bottom: 1px solid var(--accent);
  }
}

.post,
.note {
  .page-title {
    line-height: 1.2em;
  }

  .post-date {
    margin-bottom: 2em;
  }
}

.post-list {
  .sub-title {
    font-size: 1.6rem;
    margin-bottom: 0.25em;
  }
}
