.main-content {
  padding-bottom: 4rem;
}

.wrapper {
  width: 100%;
  padding: 0 1rem;

  @include bp(3) {
    width: 75%;
    max-width: rem(850px);
    margin: 0 auto;
  }
}

.wide-wrapper {
  width: 100%;
  padding: 0 1rem;

  @include bp(3) {
    width: 85%;
    max-width: rem(1150px);
    margin: 0 auto;
  }
}

.spacer {
  padding: 2em 0;

  @include bp(3) {
    padding: 4em 0;
  }
}

.inline-image {
  width: 100%;
  height: auto;
  max-width: 100%;

  &.portrait,
  &.landscape {
    @include bp(4) {
      width: auto;
      margin: 0 auto;
    }
  }

  // Homepage latest note and notes list - cropped to landscape
  .latest-note &,
  .note-list & {
    @include bp(4) {
      width: rem(400px);
      height: rem(275px);
      margin: 0 auto;
      object-fit: cover;
    }
  }
}