.contact-links {
  text-align: left;
  max-width: rem(250px);
}

.contact-link-item {
  &:not(last-child) {
    margin-bottom: 1rem;
  }

  a {
    display: flex;
    align-items: center;
  }

  svg {
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 0.5rem;
  }

  span {
    font-family: $sans;
    font-weight: 700;
  }
}
