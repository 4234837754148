.links {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.link-item {
  &:not(last-child) {
    margin-right: 0.75rem;
  }

  a {
    display: flex;
    align-items: center;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    transition: fill 0.2s linear;
    fill: var(--text);
  }

  span {
    font-family: $sans;
    color: var(--text);
    font-weight: 700;
  }
}

.link-mastodon {
  svg {
    fill: #6364ff;
  }

  &:hover {
    span {
      color: #6364ff;
    }
  }
}
.link-linkedin {
  svg {
    fill: #0077b7;
  }

  &:hover {
    span {
      color: #0077b7;
    }
  }
}
.link-github {
  svg {
    fill: var(--text);
  }

  &:hover {
    span {
      color: var(--text);
    }
  }
}
.link-contact {
  svg {
    fill: var(--accent);
  }

  &:hover {
    span {
      color: var(--accent);
    }
  }
}
.link-rss {
  svg {
    fill: #ea7819;
  }

  &:hover {
    span {
      color: #ea7819;
    }
  }
}
