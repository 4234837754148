$bookDetailSpacing: 0.3rem;

.book-item {
  margin-bottom: 3rem;
  padding-bottom: 3rem;

  &:not(:last-child) {
    border-bottom: 2px solid var(--accent);
  }

  .sub-title {
    margin-bottom: $bookDetailSpacing;
  }
}

.author {
  font-style: italic;
  margin-bottom: $bookDetailSpacing;
}

.book-meta {
  margin-bottom: 1.1rem;
  display: flex;
}

img.cover-image {
  align-self: flex-start;
  margin-right: 1.35rem;
  width: rem(100px);
  border: 1px solid var(--text);
  box-shadow: 6px 6px 0 var(--accent);
}

.rating {
  margin-bottom: $bookDetailSpacing;
}