.site-footer {
  border-top: 2px solid var(--accent);
  padding: 1rem 0;
  text-align: center;

  small {
    display: block;

    &:not(.copyright) {
      margin-bottom: 1rem;
    }
  }
}