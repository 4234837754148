/* Media Queries */

// 1: smaller than mobile
// 2: Mobile
// 3: Tablet Portrait
// 4: Tablet Landscape
// 5: Laptop
// 6: Desktop
// 7: Large Desktop

// Breakpoint above n
@mixin bp($bp) {
  @if $bp == 1 {
    @media screen and (min-width: 350px) {
      @content;
    }
  } @else if $bp == 2 {
    @media screen and (min-width: 450px) {
      @content;
    }
  } @else if $bp == 3 {
    @media screen and (min-width: 750px) {
      @content;
    }
  } @else if $bp == 4 {
    @media screen and (min-width: 1000px) {
      @content;
    }
  } @else if $bp == 5 {
    @media screen and (min-width: 1200px) {
      @content;
    }
  } @else if $bp == 6 {
    @media screen and (min-width: 1400px) {
      @content;
    }
  } @else if $bp == 7 {
    @media screen and (min-width: 1600px) {
      @content;
    }
  } @else if $bp == 8 {
    @media screen and (min-width: 1900px) {
      @content;
    }
  } @else {
    @media screen and (min-width: $bp) {
      @content;
    }
  }
}

// Breakpoint just n
@mixin bp-just($bp, $bpu: "") {
  @if $bp == 1 {
    @media screen and (min-width: 0px) and (max-width: 449px) {
      @content;
    }
  } @else if $bp == 2 {
    @media screen and (min-width: 450px) and (max-width: 749px) {
      @content;
    }
  } @else if $bp == 3 {
    @media screen and (min-width: 750px) and (max-width: 999px) {
      @content;
    }
  } @else if $bp == 4 {
    @media screen and (min-width: 1000px) and (max-width: 1199px) {
      @content;
    }
  } @else if $bp == 5 {
    @media screen and (min-width: 1200px) and (max-width: 1399px) {
      @content;
    }
  } @else if $bp == 6 {
    @media screen and (min-width: 1400px) and (max-width: 1599px) {
      @content;
    }
  } @else if $bp == 7 {
    @media screen and (min-width: 1600px) {
      @content;
    }
  } @else if $bp == mobile {
    @media screen and (min-width: 0px) and (max-width: 749px) {
      @content;
    }
  } @else if $bp == desktop {
    @media screen and (min-width: 1000px) {
      @content;
    }
  } @else {
    @media screen and (min-width: $bp) and (max-width: $bpu) {
      @content;
    }
  }
}
