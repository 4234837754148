html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  min-width: rem(320px);
  min-height: 100%;
  background: var(--bg);
  border-top: 8px solid var(--accent);

  @include bp(3) {
    display: grid;
    grid-template-rows: auto 1fr auto;
  }
}

#svg-sprite {
  position: absolute;
  width: 0;
  height: 0;
}

svg {
  width: 100%;
  height: 100%;
}