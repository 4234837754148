// Serif font stack is "Transitional" from https://modernfontstacks.com:
$serif: "Charter", "Bitstream Charter", "Sitka Text", "Cambria", "Georgia", serif;
// Sans-serif font stack is "Neo-Grotesque" from https://modernfontstacks.com:
$sans: "Inter", "Roboto", "Helvetica Neue", "Arial Nova", "Nimbus Sans", "Arial", sans-serif;

$lineHeightDefault: 1.3em;
$fontFamilyDefault: $sans;
$fontFamilySecondary: $sans;

html {
  font-size: 1rem;
  font-family: $fontFamilyDefault;
  line-height: $lineHeightDefault;
  font-display: optional;
}

a:not(.logo) {
  cursor: pointer;
  text-decoration: none;
  color: var(--accent);

  &:hover {
    text-decoration: underline;
  }
}

// Screen reader text
.srt {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* Fancy underline */
@mixin fancy-underline {
  position: relative;
  display: inline-block;
  margin-bottom: 1em;
  text-transform: uppercase;
  font-weight: bold;

  &::after {
    --deco-height: 0.3125em;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc(var(--deco-height) * -1.25);
    height: var(--deco-height);
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M-17 30.5C-1 22 72-4 54 13 37.9 28.2-2.5 57.5 16 55.5s72-29 104-40' stroke='#da6d4c' stroke-width='10'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='3daff3' d='M0 0h100v64H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-size: auto 100%;
    background-repeat: round;
    background-position: 0em;
  }
}

%fancy-underline {
  @include fancy-underline;
}

@mixin page-title {
  font-family: $fontFamilySecondary;
  font-size: 2.1rem;
  margin-bottom: 0.5em;
  line-height: 1em;
  font-weight: 400;
  color: var(--text);
  @include fancy-underline;
}

%page-title {
  @include page-title;
}

.page-title {
  @include page-title;
}

@mixin sub-title {
  font-family: $fontFamilySecondary;
  margin-bottom: 0.35em;
  line-height: 1.3em;
  font-weight: 400;
  color: var(--accent);

  a {
    color: inherit;
  }
}

%sub-title {
  @include sub-title;
}

.sub-title {
  @include sub-title;
}

/* Generic Text */
@mixin text {
  font-size: 1.1rem;
  line-height: $lineHeightDefault;
  color: var(--text);

  @include bp(3) {
    font-size: 1.2rem;
  }
}

%text {
  @include text;
}

p {
  @include text;
  margin-bottom: 1.5em;
}

blockquote {
  padding: 0 1rem;
  border-left: 3px solid var(--accent);

  p {
    font-family: $serif;
    font-style: italic;
  }
}

.post-date {
  font-size: 1.1rem;
  font-family: $fontFamilySecondary;
  margin-bottom: 1.5em;
  color: var(--accent);
}

.categories {
  color: var(--accent);
}

.meta-desc {
  color: var(--text);
}

.permalink {
  display: inline-block;
}

.note-date {
  font-family: $fontFamilySecondary;
  display: inline-block;
  margin-top: 2em;
  color: var(--text);
}

.notes-intro {
  margin-bottom: 3em;
}

small {
  @include text;
  color: var(--text);
  font-size: 0.9rem !important;
  font-family: $fontFamilySecondary;
}

code {
  font-family: monospace;
  font-size: 0.75rem;
}

/* Inline code elements inserted by using backticks in post/note content 
get a background colour (pygments syntax css handles code inserted using 
Jekyll highlight tags) */
code,
.highlight {
  font-size: 1rem;
  color: var(--codetext);
  margin-bottom: 1.5em;
  overflow-x: auto;
}

.highlight {
  padding: 0.25rem 0.5rem;

  .highlight {
    margin-bottom: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

/* User Content Areas */
.user-content {
  @include modulePaddingFix;
  @include text;
  @include clearfix;

  a {
    color: var(--accent);
  }

  br {
    height: 0.8rem;
  }

  h2,
  h3,
  h4 {
    @include sub-title;
  }

  h2 {
    font-size: 1.4rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1.2rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 700;
  }

  ul,
  ol {
    margin-left: 1.25rem;

    li {
      margin-bottom: 1rem;
      list-style-type: square;
      line-height: 1.35em;

      ul li {
        list-style-type: circle;
        margin-bottom: 0.25rem;
      }
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  img {
    width: 100%;
    height: auto;

    &.small-image {
      width: auto;
    }
  }

  .wp-caption,
  .wp-caption-text {
    width: 100% !important;
    font-size: 0.8rem;
    text-align: center;
  }

  .alignright {
    @include bp(4) {
      width: 50%;
      float: right;
      margin: 2.5em 0 2.5em 2.5em;
    }
  }

  .alignleft {
    @include bp(4) {
      width: 50%;
      float: left;
      margin: 2.5em 2.5em 2.5em 0;
    }
  }

  .aligncenter {
    margin: 0 auto;

    img {
      margin-bottom: 0.5rem;
    }
  }

  .size-full {
    width: 100%;
  }

  /* A class manually added to images embedded in WP posts to create
	a media grid when multiple images are inserted in to the post body */
  .media-grid {
    @include bp(4) {
      float: left;
      width: 50%;
      height: auto;
      max-height: rem(325px);
      object-fit: cover;

      .note-list & {
        max-height: rem(144px);
      }
    }
  }

  .media-grid-wrapper {
    @extend %clearfix;
  }
}