.note-list {
  margin-top: 3rem;

  @include bp(3) {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-gap: 1.5rem;
  }
}

.note-item {
  position: relative;
  overflow: hidden;
  padding: 1rem;
  background: var(--textbg);
  color: var(--text);
  overflow-x: hidden;
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--accent);

  @include bp(3) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  // Note content
  .user-content {
    display: flex;
    flex-direction: column;

    @include bp(3) {
      flex: 1;
    }

    // List overrides
    ul,
    ol {
      font-family: $fontFamilySecondary;
      margin: 1rem 0 1rem 1rem;
    }
  }

  p {
    font-size: 1rem;
    font-family: $fontFamilySecondary;
  }

  .note-date {
    display: block;
    font-family: $fontFamilySecondary;
    font-size: 0.9rem;
    text-align: right;

    @include bp(3) {
      margin-top: auto;
    }
  }
}