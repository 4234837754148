/* Base */
*,
:before,
:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::-ms-clear {
  display: none;
}

a {
  cursor: pointer;
}

@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}

// em units
@function em($px, $base: $base) {
  @return calc($px / $base) * 1em;
}

// rem units
@function rem($px, $base: $base) {
  @return calc($px / $base) * 1rem;
}

%hiddenAccessible {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
}

// Screen Reader Text
.srt {
  @extend %hiddenAccessible;
}

svg {
  overflow: visible;
}
