.site-header {
  padding: 0.75em 0;
  margin-bottom: 4em;

  a {
    color: var(--text);
    cursor: pointer;
  }

  svg {
    fill: var(--accent);
  }
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
