/* Base font size */
/* Plugins */
/* Base Files */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

main {
  display: block;
}

img {
  display: block;
  max-width: 100%;
}

button,
input[type=submit] {
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  font-size: 1em;
}
button:active, button:focus,
input[type=submit]:active,
input[type=submit]:focus {
  outline: 0;
}

/* Media Queries */ /* Base */
*,
:before,
:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::-ms-clear {
  display: none;
}

a {
  cursor: pointer;
}

.srt {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
}

svg {
  overflow: visible;
}

/* Mixins */
/* Clearfix */
.user-content .media-grid-wrapper {
  zoom: 1;
}
.user-content .media-grid-wrapper:before, .user-content .media-grid-wrapper:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
.user-content .media-grid-wrapper:after {
  clear: both;
}

/* Module padding fix */
/* Placeholder mixin */
/* Absolute Full Size */
/* CenterContent (x & y) using position not flexbox */
/* CenterContent (x & y) */
/* VerticallyAlign (y) */
/* Evently space (vertically around) */
/* Evently space (vertically between) */
/* Sitewide Files */
html {
  font-size: 1rem;
  font-family: "Inter", "Roboto", "Helvetica Neue", "Arial Nova", "Nimbus Sans", "Arial", sans-serif;
  line-height: 1.3em;
  font-display: optional;
}

a:not(.logo) {
  cursor: pointer;
  text-decoration: none;
  color: var(--accent);
}
a:not(.logo):hover {
  text-decoration: underline;
}

.srt {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* Fancy underline */
.page-title {
  font-family: "Inter", "Roboto", "Helvetica Neue", "Arial Nova", "Nimbus Sans", "Arial", sans-serif;
  font-size: 2.1rem;
  margin-bottom: 0.5em;
  line-height: 1em;
  font-weight: 400;
  color: var(--text);
  position: relative;
  display: inline-block;
  margin-bottom: 1em;
  text-transform: uppercase;
  font-weight: bold;
}
.page-title::after {
  --deco-height: 0.3125em;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc(var(--deco-height) * -1.25);
  height: var(--deco-height);
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M-17 30.5C-1 22 72-4 54 13 37.9 28.2-2.5 57.5 16 55.5s72-29 104-40' stroke='#da6d4c' stroke-width='10'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='3daff3' d='M0 0h100v64H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-size: auto 100%;
  background-repeat: round;
  background-position: 0em;
}

.sub-title {
  font-family: "Inter", "Roboto", "Helvetica Neue", "Arial Nova", "Nimbus Sans", "Arial", sans-serif;
  margin-bottom: 0.35em;
  line-height: 1.3em;
  font-weight: 400;
  color: var(--accent);
}
.sub-title a {
  color: inherit;
}

/* Generic Text */
p {
  font-size: 1.1rem;
  line-height: 1.3em;
  color: var(--text);
  margin-bottom: 1.5em;
}
@media screen and (min-width: 750px) {
  p {
    font-size: 1.2rem;
  }
}

blockquote {
  padding: 0 1rem;
  border-left: 3px solid var(--accent);
}
blockquote p {
  font-family: "Charter", "Bitstream Charter", "Sitka Text", "Cambria", "Georgia", serif;
  font-style: italic;
}

.post-date {
  font-size: 1.1rem;
  font-family: "Inter", "Roboto", "Helvetica Neue", "Arial Nova", "Nimbus Sans", "Arial", sans-serif;
  margin-bottom: 1.5em;
  color: var(--accent);
}

.categories {
  color: var(--accent);
}

.meta-desc {
  color: var(--text);
}

.permalink {
  display: inline-block;
}

.note-date {
  font-family: "Inter", "Roboto", "Helvetica Neue", "Arial Nova", "Nimbus Sans", "Arial", sans-serif;
  display: inline-block;
  margin-top: 2em;
  color: var(--text);
}

.notes-intro {
  margin-bottom: 3em;
}

small {
  font-size: 1.1rem;
  line-height: 1.3em;
  color: var(--text);
  color: var(--text);
  font-size: 0.9rem !important;
  font-family: "Inter", "Roboto", "Helvetica Neue", "Arial Nova", "Nimbus Sans", "Arial", sans-serif;
}
@media screen and (min-width: 750px) {
  small {
    font-size: 1.2rem;
  }
}

code {
  font-family: monospace;
  font-size: 0.75rem;
}

/* Inline code elements inserted by using backticks in post/note content 
get a background colour (pygments syntax css handles code inserted using 
Jekyll highlight tags) */
code,
.highlight {
  font-size: 1rem;
  color: var(--codetext);
  margin-bottom: 1.5em;
  overflow-x: auto;
}

.highlight {
  padding: 0.25rem 0.5rem;
}
.highlight .highlight {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

/* User Content Areas */
.user-content {
  font-size: 1.1rem;
  line-height: 1.3em;
  color: var(--text);
  zoom: 1;
  /* A class manually added to images embedded in WP posts to create
  a media grid when multiple images are inserted in to the post body */
}
.user-content > *:last-child,
.user-content > *:last-child > *:last-child,
.user-content > *:last-child > *:last-child > *:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 750px) {
  .user-content {
    font-size: 1.2rem;
  }
}
.user-content:before, .user-content:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
.user-content:after {
  clear: both;
}
.user-content a {
  color: var(--accent);
}
.user-content br {
  height: 0.8rem;
}
.user-content h2,
.user-content h3,
.user-content h4 {
  font-family: "Inter", "Roboto", "Helvetica Neue", "Arial Nova", "Nimbus Sans", "Arial", sans-serif;
  margin-bottom: 0.35em;
  line-height: 1.3em;
  font-weight: 400;
  color: var(--accent);
}
.user-content h2 a,
.user-content h3 a,
.user-content h4 a {
  color: inherit;
}
.user-content h2 {
  font-size: 1.4rem;
}
.user-content h3 {
  font-size: 1.3rem;
}
.user-content h4 {
  font-size: 1.2rem;
}
.user-content h1,
.user-content h2,
.user-content h3,
.user-content h4,
.user-content h5,
.user-content h6 {
  font-weight: 400;
}
.user-content em {
  font-style: italic;
}
.user-content strong {
  font-weight: 700;
}
.user-content ul,
.user-content ol {
  margin-left: 1.25rem;
}
.user-content ul li,
.user-content ol li {
  margin-bottom: 1rem;
  list-style-type: square;
  line-height: 1.35em;
}
.user-content ul li ul li,
.user-content ol li ul li {
  list-style-type: circle;
  margin-bottom: 0.25rem;
}
.user-content ol li {
  list-style-type: decimal;
}
.user-content img {
  width: 100%;
  height: auto;
}
.user-content img.small-image {
  width: auto;
}
.user-content .wp-caption,
.user-content .wp-caption-text {
  width: 100% !important;
  font-size: 0.8rem;
  text-align: center;
}
@media screen and (min-width: 1000px) {
  .user-content .alignright {
    width: 50%;
    float: right;
    margin: 2.5em 0 2.5em 2.5em;
  }
}
@media screen and (min-width: 1000px) {
  .user-content .alignleft {
    width: 50%;
    float: left;
    margin: 2.5em 2.5em 2.5em 0;
  }
}
.user-content .aligncenter {
  margin: 0 auto;
}
.user-content .aligncenter img {
  margin-bottom: 0.5rem;
}
.user-content .size-full {
  width: 100%;
}
@media screen and (min-width: 1000px) {
  .user-content .media-grid {
    float: left;
    width: 50%;
    height: auto;
    max-height: 20.3125rem;
    object-fit: cover;
  }
  .note-list .user-content .media-grid {
    max-height: 9rem;
  }
}
:root {
  --bg: #fffaed;
  --text: #1e1e1e;
  --codetext: #d53a61;
  --accent: #da6d4c;
  --accentrgb: 218, 109, 76;
  --textbg: #ffede8;
}

/* Modules Files */
.highlight .hll {
  background-color: #49483e;
}

.highlight {
  background: #272822;
  color: #f8f8f2;
}

.highlight .c {
  color: #75715e;
} /* Comment */
.highlight .err {
  color: #960050;
  background-color: #1e0010;
} /* Error */
.highlight .k {
  color: #66d9ef;
} /* Keyword */
.highlight .l {
  color: #ae81ff;
} /* Literal */
.highlight .n {
  color: #f8f8f2;
} /* Name */
.highlight .o {
  color: #f92672;
} /* Operator */
.highlight .p {
  color: #f8f8f2;
} /* Punctuation */
.highlight .ch {
  color: #75715e;
} /* Comment.Hashbang */
.highlight .cm {
  color: #75715e;
} /* Comment.Multiline */
.highlight .cp {
  color: #75715e;
} /* Comment.Preproc */
.highlight .cpf {
  color: #75715e;
} /* Comment.PreprocFile */
.highlight .c1 {
  color: #75715e;
} /* Comment.Single */
.highlight .cs {
  color: #75715e;
} /* Comment.Special */
.highlight .gd {
  color: #f92672;
} /* Generic.Deleted */
.highlight .ge {
  font-style: italic;
} /* Generic.Emph */
.highlight .gi {
  color: #a6e22e;
} /* Generic.Inserted */
.highlight .gs {
  font-weight: bold;
} /* Generic.Strong */
.highlight .gu {
  color: #75715e;
} /* Generic.Subheading */
.highlight .kc {
  color: #66d9ef;
} /* Keyword.Constant */
.highlight .kd {
  color: #66d9ef;
} /* Keyword.Declaration */
.highlight .kn {
  color: #f92672;
} /* Keyword.Namespace */
.highlight .kp {
  color: #66d9ef;
} /* Keyword.Pseudo */
.highlight .kr {
  color: #66d9ef;
} /* Keyword.Reserved */
.highlight .kt {
  color: #66d9ef;
} /* Keyword.Type */
.highlight .ld {
  color: #e6db74;
} /* Literal.Date */
.highlight .m {
  color: #ae81ff;
} /* Literal.Number */
.highlight .s {
  color: #e6db74;
} /* Literal.String */
.highlight .na {
  color: #a6e22e;
} /* Name.Attribute */
.highlight .nb {
  color: #f8f8f2;
} /* Name.Builtin */
.highlight .nc {
  color: #a6e22e;
} /* Name.Class */
.highlight .no {
  color: #66d9ef;
} /* Name.Constant */
.highlight .nd {
  color: #a6e22e;
} /* Name.Decorator */
.highlight .ni {
  color: #f8f8f2;
} /* Name.Entity */
.highlight .ne {
  color: #a6e22e;
} /* Name.Exception */
.highlight .nf {
  color: #a6e22e;
} /* Name.Function */
.highlight .nl {
  color: #f8f8f2;
} /* Name.Label */
.highlight .nn {
  color: #f8f8f2;
} /* Name.Namespace */
.highlight .nx {
  color: #a6e22e;
} /* Name.Other */
.highlight .py {
  color: #f8f8f2;
} /* Name.Property */
.highlight .nt {
  color: #f92672;
} /* Name.Tag */
.highlight .nv {
  color: #f8f8f2;
} /* Name.Variable */
.highlight .ow {
  color: #f92672;
} /* Operator.Word */
.highlight .w {
  color: #f8f8f2;
} /* Text.Whitespace */
.highlight .mb {
  color: #ae81ff;
} /* Literal.Number.Bin */
.highlight .mf {
  color: #ae81ff;
} /* Literal.Number.Float */
.highlight .mh {
  color: #ae81ff;
} /* Literal.Number.Hex */
.highlight .mi {
  color: #ae81ff;
} /* Literal.Number.Integer */
.highlight .mo {
  color: #ae81ff;
} /* Literal.Number.Oct */
.highlight .sa {
  color: #e6db74;
} /* Literal.String.Affix */
.highlight .sb {
  color: #e6db74;
} /* Literal.String.Backtick */
.highlight .sc {
  color: #e6db74;
} /* Literal.String.Char */
.highlight .dl {
  color: #e6db74;
} /* Literal.String.Delimiter */
.highlight .sd {
  color: #e6db74;
} /* Literal.String.Doc */
.highlight .s2 {
  color: #e6db74;
} /* Literal.String.Double */
.highlight .se {
  color: #ae81ff;
} /* Literal.String.Escape */
.highlight .sh {
  color: #e6db74;
} /* Literal.String.Heredoc */
.highlight .si {
  color: #e6db74;
} /* Literal.String.Interpol */
.highlight .sx {
  color: #e6db74;
} /* Literal.String.Other */
.highlight .sr {
  color: #e6db74;
} /* Literal.String.Regex */
.highlight .s1 {
  color: #e6db74;
} /* Literal.String.Single */
.highlight .ss {
  color: #e6db74;
} /* Literal.String.Symbol */
.highlight .bp {
  color: #f8f8f2;
} /* Name.Builtin.Pseudo */
.highlight .fm {
  color: #a6e22e;
} /* Name.Function.Magic */
.highlight .vc {
  color: #f8f8f2;
} /* Name.Variable.Class */
.highlight .vg {
  color: #f8f8f2;
} /* Name.Variable.Global */
.highlight .vi {
  color: #f8f8f2;
} /* Name.Variable.Instance */
.highlight .vm {
  color: #f8f8f2;
} /* Name.Variable.Magic */
.highlight .il {
  color: #ae81ff;
} /* Literal.Number.Integer.Long */
.logo {
  display: block;
  width: 3.5em;
  height: 3.5em;
  margin-right: auto;
}

.site-nav ul {
  display: flex;
  font-family: "Inter", "Roboto", "Helvetica Neue", "Arial Nova", "Nimbus Sans", "Arial", sans-serif;
}

a.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.15em;
  margin: 0.3em;
  color: var(--text);
}
@media screen and (min-width: 750px) {
  a.nav-link {
    display: inline-block;
    font-size: 1.2em;
    margin: 0.5em;
  }
}
a.nav-link:hover {
  text-decoration: none;
}
a.nav-link:hover span {
  text-decoration: underline;
}

.current-menu-item .nav-link,
.current-menu-item .nav-link span {
  text-decoration: underline;
}

.menu-text {
  display: inline-block;
  margin-top: 0.5rem;
}
@media screen and (min-width: 750px) {
  .menu-text {
    margin-top: 0;
  }
}

.links {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.link-item:not(last-child) {
  margin-right: 0.75rem;
}
.link-item a {
  display: flex;
  align-items: center;
}
.link-item svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.25rem;
  transition: fill 0.2s linear;
  fill: var(--text);
}
.link-item span {
  font-family: "Inter", "Roboto", "Helvetica Neue", "Arial Nova", "Nimbus Sans", "Arial", sans-serif;
  color: var(--text);
  font-weight: 700;
}

.link-mastodon svg {
  fill: #6364ff;
}
.link-mastodon:hover span {
  color: #6364ff;
}

.link-linkedin svg {
  fill: #0077b7;
}
.link-linkedin:hover span {
  color: #0077b7;
}

.link-github svg {
  fill: var(--text);
}
.link-github:hover span {
  color: var(--text);
}

.link-contact svg {
  fill: var(--accent);
}
.link-contact:hover span {
  color: var(--accent);
}

.link-rss svg {
  fill: #ea7819;
}
.link-rss:hover span {
  color: #ea7819;
}

/* Site Chrome Files */
.main-content {
  padding-bottom: 4rem;
}

.wrapper {
  width: 100%;
  padding: 0 1rem;
}
@media screen and (min-width: 750px) {
  .wrapper {
    width: 75%;
    max-width: 53.125rem;
    margin: 0 auto;
  }
}

.wide-wrapper {
  width: 100%;
  padding: 0 1rem;
}
@media screen and (min-width: 750px) {
  .wide-wrapper {
    width: 85%;
    max-width: 71.875rem;
    margin: 0 auto;
  }
}

.spacer {
  padding: 2em 0;
}
@media screen and (min-width: 750px) {
  .spacer {
    padding: 4em 0;
  }
}

.inline-image {
  width: 100%;
  height: auto;
  max-width: 100%;
}
@media screen and (min-width: 1000px) {
  .inline-image.portrait, .inline-image.landscape {
    width: auto;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1000px) {
  .latest-note .inline-image, .note-list .inline-image {
    width: 25rem;
    height: 17.1875rem;
    margin: 0 auto;
    object-fit: cover;
  }
}

.site-header {
  padding: 0.75em 0;
  margin-bottom: 4em;
}
.site-header a {
  color: var(--text);
  cursor: pointer;
}
.site-header svg {
  fill: var(--accent);
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-footer {
  border-top: 2px solid var(--accent);
  padding: 1rem 0;
  text-align: center;
}
.site-footer small {
  display: block;
}
.site-footer small:not(.copyright) {
  margin-bottom: 1rem;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  min-width: 20rem;
  min-height: 100%;
  background: var(--bg);
  border-top: 8px solid var(--accent);
}
@media screen and (min-width: 750px) {
  body {
    display: grid;
    grid-template-rows: auto 1fr auto;
  }
}

#svg-sprite {
  position: absolute;
  width: 0;
  height: 0;
}

svg {
  width: 100%;
  height: 100%;
}

/* Pages */
.mike-pic {
  width: 12.5rem;
  height: 12.5rem;
  border: 3px solid var(--accent);
  border-radius: 50%;
  margin: 0 auto 2rem auto;
}
@media screen and (min-width: 1000px) {
  .mike-pic {
    float: right;
    shape-outside: circle();
    margin: 1rem 0 1rem 2rem;
  }
}

.home-titles {
  position: relative;
}
.home-titles .titles {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 1000px) {
  .home-titles .titles {
    display: block;
  }
}
.home-titles .titles .page-title {
  margin-bottom: 1.2rem;
}
@media screen and (min-width: 1000px) {
  .home-titles .titles .page-title {
    margin-bottom: 0.5em;
  }
}
.home-titles .titles .sub-title {
  color: var(--text);
  font-size: 1.4rem;
  margin-bottom: 1.2rem;
}
@media screen and (min-width: 1000px) {
  .home-titles .titles .sub-title {
    margin-bottom: 0.35em;
  }
}

.home-content {
  margin-bottom: 3rem;
}

.bio {
  zoom: 1;
  margin-bottom: 3rem;
}
.bio:before, .bio:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
.bio:after {
  clear: both;
}
.bio p {
  margin-bottom: 0;
}

@media screen and (min-width: 1000px) {
  .recent-content {
    display: flex;
    gap: 3rem;
  }
}
.recent-content p {
  font-size: 1.1rem;
}
.recent-content .sub-title {
  font-size: 1.4rem;
  color: var(--accent);
}
.recent-content section:first-of-type {
  margin-bottom: 3rem;
}
@media screen and (min-width: 1000px) {
  .recent-content section:first-of-type {
    max-width: 55%;
  }
}
@media screen and (min-width: 1000px) {
  .recent-content section:last-of-type {
    max-width: 45%;
  }
}

.latest-note *:last-child {
  margin-bottom: 0;
}

.latest-post p {
  margin-bottom: 1rem;
}
.latest-post .post-date {
  display: block;
  margin-bottom: 0;
}

.post-item {
  padding-bottom: 3rem;
}
.post-item:not(:last-child) {
  margin-bottom: 3rem;
  border-bottom: 1px solid var(--accent);
}

.post .page-title,
.note .page-title {
  line-height: 1.2em;
}
.post .post-date,
.note .post-date {
  margin-bottom: 2em;
}

.post-list .sub-title {
  font-size: 1.6rem;
  margin-bottom: 0.25em;
}

.note-list {
  margin-top: 3rem;
}
@media screen and (min-width: 750px) {
  .note-list {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-gap: 1.5rem;
  }
}

.note-item {
  position: relative;
  overflow: hidden;
  padding: 1rem;
  background: var(--textbg);
  color: var(--text);
  overflow-x: hidden;
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--accent);
}
@media screen and (min-width: 750px) {
  .note-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }
}
.note-item .user-content {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 750px) {
  .note-item .user-content {
    flex: 1;
  }
}
.note-item .user-content ul,
.note-item .user-content ol {
  font-family: "Inter", "Roboto", "Helvetica Neue", "Arial Nova", "Nimbus Sans", "Arial", sans-serif;
  margin: 1rem 0 1rem 1rem;
}
.note-item p {
  font-size: 1rem;
  font-family: "Inter", "Roboto", "Helvetica Neue", "Arial Nova", "Nimbus Sans", "Arial", sans-serif;
}
.note-item .note-date {
  display: block;
  font-family: "Inter", "Roboto", "Helvetica Neue", "Arial Nova", "Nimbus Sans", "Arial", sans-serif;
  font-size: 0.9rem;
  text-align: right;
}
@media screen and (min-width: 750px) {
  .note-item .note-date {
    margin-top: auto;
  }
}

.contact-links {
  text-align: left;
  max-width: 15.625rem;
}

.contact-link-item:not(last-child) {
  margin-bottom: 1rem;
}
.contact-link-item a {
  display: flex;
  align-items: center;
}
.contact-link-item svg {
  width: 2.25rem;
  height: 2.25rem;
  margin-right: 0.5rem;
}
.contact-link-item span {
  font-family: "Inter", "Roboto", "Helvetica Neue", "Arial Nova", "Nimbus Sans", "Arial", sans-serif;
  font-weight: 700;
}

.book-item {
  margin-bottom: 3rem;
  padding-bottom: 3rem;
}
.book-item:not(:last-child) {
  border-bottom: 2px solid var(--accent);
}
.book-item .sub-title {
  margin-bottom: 0.3rem;
}

.author {
  font-style: italic;
  margin-bottom: 0.3rem;
}

.book-meta {
  margin-bottom: 1.1rem;
  display: flex;
}

img.cover-image {
  align-self: flex-start;
  margin-right: 1.35rem;
  width: 6.25rem;
  border: 1px solid var(--text);
  box-shadow: 6px 6px 0 var(--accent);
}

.rating {
  margin-bottom: 0.3rem;
}

/* Shame */
/*

Shame.scss - a Brief Guide
==========================

* What is this for?
-------------------

The short version:

Shameful CSS hacks and overrides that aren't pretty, but get
the job done in a pinch. Use very sparingly, as a last resort,
and refactor hacks back out of this file as often as possible
to keep our CSS lean and clean.

The longer version:

_shame.scss exists as a single place to keep all the little
quick fixes and CSS hacks that you know aren't ideal, but
for some reason just have to be applied to keep a project on
track for a deadline or for some other critically important
reason that means you're not able to do something "properly".

By keeping all those little hacks in one place we can tell at
a glance when things are getting messy and hard to maintain,
and identify particular site modules, features, or patterns
that are causing problems.

When it comes to refactoring to make the codebase easier to
maintain, _shame.scss should be the first stop. Move hacks
out of it by rewriting them properly in to the CSS as often
as possible.


* How do I use it?
------------------

If you're adding to _shame.scss, give your reason in a brief
comment above the hack. Find the thing you're hacking your
way around or overriding in the main CSS and add a brief
comment there too, pointing other developers to the hack you
added in this file.

If you're removing things from _shame.scss (thank you, you're
a hero! \o/), be sure to remove comments from the main CSS as
well once you've finished refactoring the hack in to something
more palatable. And thank you again!

*/