.mike-pic {
  width: rem(200px);
  height: rem(200px);
  border: 3px solid var(--accent);
  border-radius: 50%;
  margin: 0 auto 2rem auto;

  @include bp(4) {
    float: right;
    shape-outside: circle();
    margin: 1rem 0 1rem 2rem;
  }
}

.home-titles {
  position: relative;

  .titles {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include bp(4) {
      display: block;
    }

    .page-title {
      margin-bottom: 1.2rem;

      @include bp(4) {
        margin-bottom: 0.5em; // restores value from _typography.scss
      }
    }

    .sub-title {
      color: var(--text);
      font-size: 1.4rem;
      margin-bottom: 1.2rem;

      @include bp(4) {
        margin-bottom: 0.35em; // restores value from _typography.scss
      }
    }
  }

}

.home-content {
  margin-bottom: 3rem;
}

.bio {
  @include clearfix;
  margin-bottom: 3rem;

  p {
    margin-bottom: 0;
  }
}

.recent-content {
  @include bp(4) {
    display: flex;
    gap: 3rem;
  }

  p {
    font-size: 1.1rem;
  }

  .sub-title {
    font-size: 1.4rem;
    color: var(--accent);
  }

  section:first-of-type {
    margin-bottom: 3rem;

    @include bp(4) {
      max-width: 55%;
    }
  }

  section:last-of-type {

    @include bp(4) {
      max-width: 45%;
    }
  }
}

.latest-note {
  *:last-child {
    margin-bottom: 0;
  }
}

.latest-post {
  p {
    margin-bottom: 1rem;
  }

  .post-date {
    display: block;
    margin-bottom: 0;
  }
}