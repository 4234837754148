/* Clearfix */
@mixin clearfix {
  zoom: 1;
  &:before,
  &:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden;
  }
  &:after {
    clear: both;
  }
}
%clearfix {
  @include clearfix;
}

/* Module padding fix */
@mixin modulePaddingFix {
  > *:last-child,
  > *:last-child > *:last-child,
  > *:last-child > *:last-child > *:last-child {
    margin-bottom: 0;
  }
}
%modulePaddingFix {
  @include modulePaddingFix;
}

/* Placeholder mixin */
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

/* Absolute Full Size */
@mixin absoluteFullSize {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  content: "";
}
%absoluteFullSize {
  @include absoluteFullSize;
}

/* CenterContent (x & y) using position not flexbox */
@mixin absoluteCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
%absoluteCenter {
  @include absoluteCenter;
}

/* CenterContent (x & y) */
@mixin centerContent {
  position: relative;
  width: 100%;

  .content-container {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    max-height: 100%;
    text-align: center;
  }

  // Flexbox version
  .flexbox & {
    display: flex;
    align-items: center;
    justify-content: center;

    .content-container {
      position: static;
      left: auto;
      top: auto;
      transform: translate(0, 0);
    }
  }
}
%centerContent {
  @include centerContent;
}

/* VerticallyAlign (y) */
@mixin verticallyAlign {
  position: relative;

  .content-container {
    position: absolute;
    left: 0%;
    top: 50%;
    z-index: 2;
    transform: translate(0, -50%);
    max-height: 100%;
    text-align: left;
  }

  // Flexbox version
  .flexbox & {
    display: flex;
    align-items: center;
    flex-direction: column;

    .content-container {
      position: static;
      left: auto;
      top: auto;
      transform: translate(0, 0);
    }
  }
}
%verticallyAlign {
  @include verticallyAlign;
}

/* Evently space (vertically around) */
@mixin verticalSpaceAround {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  // no Flexbox version
  .no-flexbox & {
    text-align: center;

    > * {
      margin: 0.75em auto;
    }
  }
}
%verticalSpaceAround {
  @include verticalSpaceAround;
}

/* Evently space (vertically between) */
@mixin verticalSpaceBetween {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  // no Flexbox version
  .no-flexbox & {
    text-align: center;

    > * {
      margin: 0.75em auto;
    }
  }
}
%verticalSpaceBetween {
  @include verticalSpaceBetween;
}
