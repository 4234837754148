.site-nav ul {
  display: flex;
  font-family: $fontFamilySecondary;
}

a.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.15em;
  margin: 0.3em;
  color: var(--text);

  @include bp(3) {
    display: inline-block;
    font-size: 1.2em;
    margin: 0.5em;
  }

  // Overriding styling set on a:not(.logo) in typography.scss
  &:hover {
    text-decoration: none;
  }

  &:hover span {
    text-decoration: underline;
  }
}

.current-menu-item .nav-link,
.current-menu-item .nav-link span {
  text-decoration: underline;
}

.menu-text {
  display: inline-block;
  margin-top: 0.5rem;

  @include bp(3) {
    margin-top: 0;
  }
}